import { useRef } from 'react';
import ParticulesDesign from './ParticulesDesign';
import ParticulesDeveloppement from './ParticulesDeveloppement';
import ParticulesErgonomie from './ParticulesErgonomie';

const ExpertisesDesign = () => {
    const logo = useRef(null)
    const images = useRef(null)
    const colorimetrie = useRef(null)
    const typographie = useRef(null)
    const ergonomie = useRef(null)

    const executeScrollToLogo = () => logo.current.scrollIntoView({ behavior: 'smooth' })
    const executeScrollToImages = () => images.current.scrollIntoView({ behavior: 'smooth' })
    const executeScrollToColorimetrie = () => colorimetrie.current.scrollIntoView({ behavior: 'smooth' })
    const executeScrollToTypographie = () => typographie.current.scrollIntoView({ behavior: 'smooth' })
    const executeScrollToErgonomie = () => ergonomie.current.scrollIntoView({ behavior: 'smooth' })

    return (<>

        <section id="design-modal" className="expertises-design hidden pt-0" >
            <div className="page">
                <div className='p-0'>
                    <div className="m-0" style={{ background: 'url(assets/img/design.webp) center 0px / cover no-repeat ', height: '500px' }}>
                        <div className="page text-center" style={{ paddingTop: 280 }}>
                            <h2 className='text-white'>Design UI/UX</h2>
                            <div className="design crumbs ">
                                <nav aria-label="breadcrumb" className="breadcrumb-items">
                                    <ol className="breadcrumb ">
                                        <li className="breadcrumb-design-item "><button onClick={executeScrollToLogo}>Logo</button></li>
                                        <li className="breadcrumb-design-item"><button onClick={executeScrollToImages}>Images</button></li>
                                        <li className="breadcrumb-design-item"><button onClick={executeScrollToColorimetrie}>Colorimétrie</button></li>
                                        <li className="breadcrumb-design-item"><button onClick={executeScrollToTypographie}>Typographie</button></li>
                                        <li className="breadcrumb-design-item"><button onClick={executeScrollToErgonomie}>Ergonomie</button></li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                    {/*content*/}
                    <div className="blog-content mb-5">
                        <div className="container">
                            <div className="row no-gutters">
                                <div className="col-12">
                                    {/* START HEADING SECTION */}
                                    <div className="standalone-detail pb-0">
                                        <div className="row no-gutters">
                                            <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2  text-center wow slideInUp" data-wow-duration="2s">
                                                <p className="p-5 m-auto">
                                                    La <strong>charte graphique</strong> représente l'identité visuelle de votre projet.<br />
                                                    Nous travaillons en ce sens en agissant sur cinq points essentiels.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    {/*content*/}
                                    <div className="standalone-area">
                                        <div className="row standalone-row align-items-center no-gutters" id="logo" ref={logo}>
                                            <div className="col-lg-6">
                                                <div className="blog-image wow hover-effect fadeInLeft">
                                                    <ParticulesDeveloppement />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 stand-img-des">
                                                <div className="d-inline-block">
                                                    <h2 className="gradient-text1 pt-3 pt-lg-0">Logo</h2>
                                                    <p className="para_text">
                                                        Qu’on le veuille ou non, le logo est suffisamment puissant pour affecter votre perception quant aux attributs d’un produit, d’une marque ou d'un site web.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/*content*/}
                                        <div className="row standalone-row align-items-center no-gutters" id="images" ref={images}>
                                            <div className="col-lg-6 order-lg-2">
                                                <div className="blog-image wow hover-effect fadeInLeft">
                                                    <img id="particulesCharte" src="assets/img/charte-graphique.webp" alt="charte graphique" loading="lazy" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 stand-img-des">
                                                <div className="d-inline-block">
                                                    <h2 className="gradient-text1 pt-3 pt-lg-0">Images</h2>
                                                    <p className="para_text">
                                                        Qu'elle soit classique ou mobile, la navigation web se doit de conserver une homogénéité dans l'affichage de vos images au sein
                                                        de differentes résolutions graphiques.

                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/*content*/}
                                        <div className="row standalone-row align-items-center no-gutters" id="colorimetrie" ref={colorimetrie}>
                                            <div className="col-lg-6">
                                                <div className="blog-image wow hover-effect fadeInLeft">
                                                    <ParticulesDesign />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 stand-img-des">
                                                <div className="d-inline-block">
                                                    <h2 className="gradient-text1 pt-3 pt-lg-0">Colorimétrie</h2>
                                                    <p className="para_text">
                                                        Selon la psychologie des couleurs, chacune d'elle possède son effet émotionnel propre et induit un comportement précis.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/*content*/}
                                        <div className="row standalone-row align-items-center no-gutters" id="typographie" ref={typographie}>
                                            <div className="col-lg-6 order-lg-2">
                                                <div className="blog-image wow hover-effect fadeInLeft">
                                                    <img id="particulesTypographie" src="assets/img/typographie.webp" alt="typographie" loading="lazy" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 stand-img-des">
                                                <div className="d-inline-block">
                                                    <h2 className="gradient-text1 pt-3 pt-lg-0">Typographie</h2>
                                                    <p className="para_text">
                                                        La typographie définit l’apparence de vos textes.
                                                        C’est pour cette raison que les polices d’écriture doivent être lisibles, agréables, mettant en valeur votre contenu tout en affirmant votre identité visuelle.                                                            </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/*content*/}
                                        <div className="row standalone-row align-items-center no-gutters" id="ergonomie" ref={ergonomie}>
                                            <div className="col-lg-6">
                                                <div className="blog-image wow hover-effect fadeInLeft">
                                                    <ParticulesErgonomie />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 stand-img-des">
                                                <div className="d-inline-block">
                                                    <h2 className="gradient-text1 pt-3 pt-lg-0">Ergonomie</h2>
                                                    <p className="para_text">
                                                        Clarté, visibilité, simplicité et cohérence.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* END HEADING SECTION */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
    );
};

export default ExpertisesDesign;