import React from 'react';
import Particles from "react-tsparticles";
import { loadSeaAnemonePreset } from "tsparticles-preset-sea-anemone";

const ParticulesDesign = () => {

  const particlesInit = (main) => {
    loadSeaAnemonePreset(main);

    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
  };

  const particlesLoaded = () => {
  };

  const options = {
    preset: "seaAnemone",
    background: {
      color: {
        value: "#361930",
      },
    },
    fpsLimit: 60,
    fullScreen: {
      enable: false
    }
  };

  return (
    <Particles options={options}
      id="particuleDesign"
      init={particlesInit}
      loaded={particlesLoaded} />
  );
};

export default ParticulesDesign;