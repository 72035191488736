import React from 'react';

const ExpertisesDeveloppement = () => {
    return (
        <div className="expertises-developpement hidden">

            {/*slider sec end*/}
            <div className='h-100'>

                <div className="inner-wrapper page">

                    <nav className="side-nav w-100 ">
                        <h2 className='pb-5'>Développement</h2>
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <a id="btnApplication" className="nav-link  close-demos scroll" href="#application">Application</a>
                            </li>
                            <li className="nav-item">
                                <a id="btnCreationSiteWeb" className="nav-link close-demos scroll" href="#creation-site-web">Site vitrine</a>
                            </li>
                            <li className="nav-item">
                                <a id="btnSiteEcommerce" className="nav-link  close-demos scroll" href="#site-ecommerce">Site e-commerce</a>
                            </li>

                            <li className="nav-item">
                                <a id="btnBlockchain" className="nav-link  close-demos scroll" href="#blockchain">Blockchain</a>
                            </li>
                        </ul>

                    </nav>
                </div>
                {/*main page content end*/}
            </div>
        </div>
    );
};

export default ExpertisesDeveloppement;