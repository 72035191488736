import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-light-custom text-center p-0 pb-2">
      <h2 className="d-none">hidden</h2>
      <div className="row no-gutters align-items-center h-100">
        <div className="col-12">
          {/* <div className="footer-social">
              <ul className="list-unstyled">
                 <li><a class="wow fadeInDown" href="/#"><i class="fab fa-twitter" aria-hidden="true"></i></a></li>
                  <li><a class="wow fadeInUp" href="/#"><i class="fab fa-google-plus-g" aria-hidden="true"></i></a></li>
                  <li><a class="wow fadeInDown" href="/#"><i class="fab fa-linkedin-in" aria-hidden="true"></i></a></li>
                  <li><a class="wow fadeInUp" href="/#"><i class="fab fa-instagram" aria-hidden="true"></i></a></li>
                  <li><a class="wow fadeInDown" href="/#"><i class="fab fa-youtube" aria-hidden="true"></i></a></li>
              </ul> 
              </ul>
              </div>*/}
          <p className="company-about fadeIn">© 2021 myDev. Made With 💚 </p>
        </div>
      </div>
    </footer>

  );
};

export default Footer;