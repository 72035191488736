import React from 'react';

const MapGoogle = () => {
  return (
    <div className="map-area">
      <div className="row no-gutters">
        <div className="col-12">
          <iframe title="Map adresse myDev" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2528.3560076090475!2d3.1676236156611877!3d50.67621647950719!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c32858cf4118d1%3A0xc1913a5ef9a9f3a1!2s35%20Av.%20Gustave%20Delory%2C%2059100%20Roubaix!5e0!3m2!1sfr!2sfr!4v1642697500599!5m2!1sfr!2sfr" width={100} height={100} style={{ border: 0 }} allowFullScreen loading="lazy"></iframe>
        </div>
      </div>
    </div>

  );
};

export default MapGoogle;