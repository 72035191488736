import React from 'react';

const BoutonRetour = () => {
    return (
        <>
            <span className="btn-return" style={{ display: 'none' }} ><i className="las la-angle-left"></i></span>
        </>
    );
};

export default BoutonRetour;