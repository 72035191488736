import React, { useState } from "react";
import axios from "axios";



const Contact = () => {

  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState("");
  const [sujet, setSujet] = useState("");
  const [message, setMessage] = useState("");
  const [mailEnvoye, setMailEnvoye] = useState(false);
  const API_PATH = 'https://contact.mydev.fr/api/contact.php';



  const handleSubmit = (e) => {
    e.preventDefault();

    axios({
      method: 'post',
      url: API_PATH,
      headers: {
        'content-type': 'application/json'
      },
      data: [nom, prenom, email, telephone, sujet, message],
    })
      .then(result => {
        // console.log(result.data)
        setNom("")
        setPrenom("")
        setEmail("")
        setTelephone("")
        setSujet("")
        setMessage("")
        setMailEnvoye(true);
      })
      .catch(error => {

      })
  };
  let isEmailOk = '';

  if (mailEnvoye) {
    isEmailOk = <div className="text-center">
      <p>Merci 😊<br />
        Nous reviendrons vers vous très vite.
      </p>
    </div>
  }

  return (
    <section className="single-items center-block parallax contact-sec lazy" id="contact" style={{ background: 'url(assets/img/contact.webp)' }}>
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-5 offset-lg-7 contact-box text-center text-md-left">
            <div className="c-box wow fadeInRight">
              <h4 className="small-heading text-center">Contactez <strong>Nous</strong></h4>
              {isEmailOk}

              <form className="contact-form" id="contact-form-data" onSubmit={(e) => handleSubmit(e)}>
                <div className="row my-form">
                  <div className="col-sm-12" id="result" />
                  <div className="col-12 col-md-6">
                    <input
                      onChange={(e) => setNom(e.target.value)}
                      type="text"
                      className="form-control"
                      id="candidate_fname"
                      name="firstName"
                      placeholder="Nom*"
                      required="required"
                      value={nom}
                    />

                  </div>
                  <div className="col-12 col-md-6">
                    <input
                      onChange={(e) => setPrenom(e.target.value)}
                      type="text"
                      className="form-control"
                      id="candidate_lname"
                      name="lastName"
                      placeholder="Prénom*"
                      required="required"
                      value={prenom}
                    />

                  </div>
                  {/* <div className="col-12 col-md-6">
                    <input type="text" className="form-control" id="candidate_lname" name="lastName" placeholder="Prénom*" required="required" />
                  </div> */}
                  <div className="col-12 col-md-6">
                    <input
                      onChange={(e) => setEmail(e.target.value)}
                      type="email"
                      className="form-control"
                      id="user_email"
                      name="userEmail"
                      placeholder="Adresse E-mail*"
                      required="required"
                      value={email}

                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <input
                      onChange={(e) => setTelephone(e.target.value)}
                      type="tel"
                      className="form-control"
                      id="user_phone"
                      name="userPhone"
                      placeholder="Téléphone"
                      value={telephone}

                    />
                  </div>
                  <div className="col-12">
                    <select
                      onChange={(e) => setSujet(e.target.value)}
                      className="form-control"
                      id="user_subject"
                      name="userSubject"
                      required="required"
                      style={{ border: '1px solid #f5f5dc' }}
                      value={sujet} >

                      <option>Choisissez un sujet :</option>
                      <option>Une demande d'informations</option>
                      <option>Un devis</option>
                      <option>Une autre demande</option>
                    </select>
                  </div>
                  <div className="col-12">
                    <textarea
                      onChange={(e) => setMessage(e.target.value)}
                      value={message}
                      className="form-control"
                      id="user_message"
                      name="userMessage"
                      placeholder="Votre Message*"
                      rows={6}
                      required="required" ></textarea>
                  </div>
                  <div className="col-12">
                    <button className="btn rounded-pill user-contact contact_btn" type="submit"><i className="fa fa-spinner fa-spin mr-2 d-none" aria-hidden="true" ></i>ENVOYER
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div >
    </section >


  );
};

export default Contact;