import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import TagManager from 'react-gtm-module'

if (window.location.pathname === "/") {
  const tagManagerArgs = {
    gtmId: 'GTM-NXBTZ44'
  }
  TagManager.initialize(tagManagerArgs)
}
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);


