import React from 'react';

const OuSommesNous = () => {
  return (
    <section className="single-items center-block parallax contact-us-sec lazy" id="ou-sommes-nous" style={{ background: 'url(assets/img/moto.webp)' }}>
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6 contact-details text-center text-lg-left" style={{ backgroundColor: '#361830ad' }}>
            <div className="location-details color-white">
              <h4 className="contact-heading">Où <strong>sommes-nous ?</strong></h4>
              <p className="contact-address mb-0"><strong>myDev</strong></p>
              <p className="contact-address">35 Avenue Gustave Delory, 59100 Roubaix</p>
              <ul className="contact-list">
                <li><span>Email :</span> <a href="mailto:contact@mydev.fr" className='cta-email'>contact@mydev.fr</a></li>
                <li><span>Siret :</span> <a href="https://www.societe.com/etablissement/mydev-80862288000033.html" className='cta-siret' rel="rel=noopener, noreferrer" target="_blank">80862288000033</a></li>
              </ul>
              <ul className="slider-social contact-s-media">
                {/* <li class="animated-wrap"><a class="animated-element" href="javascript:void(0);"><i class="lab la-twitter"></i> </a> </li>
                      <li class="animated-wrap"><a class="animated-element" href="javascript:void(0);"><i class="lab la-google-plus-g"></i> </a> </li>
                      <li class="animated-wrap"><a class="animated-element" href="javascript:void(0);"><i class="lab la-linkedin-in"></i> </a> </li>
                      <li class="animated-wrap"><a class="animated-element" href="javascript:void(0);"><i class="lab la-instagram"></i> </a> </li>
                  </ul> */}
              </ul></div>
          </div>
        </div>
      </div>
    </section>

  );
};

export default OuSommesNous;