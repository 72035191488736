import React from 'react';

const ExpertisesMobile = () => {
    return (
        <div className="expertises-mobile hidden">


            <video playsInline="playsinline" autoPlay="autoplay" muted="muted" loop="loop">
                <source src="assets/videos/mobile.mp4" type="video/mp4" />
            </video>

            <div className='container-mobile'>

                <div className='h-100'>
                    <div className="inner-wrapper page">
                        <nav className="side-nav w-100 ">
                            <div className='row'>
                                <div className="col-12 col-lg-5 d-none d-lg-flex">
                                    <div className="iPhone">
                                        <div className="iPhone__button iPhone__button--left iPhone__button--silent-switch" ></div>
                                        <div className="iPhone__button iPhone__button--left iPhone__button--volume iPhone__button--volume-up" ></div>
                                        <div className="iPhone__button iPhone__button--left iPhone__button--volume iPhone__button--volume-down" ></div>
                                        <div className="iPhone__button iPhone__button--right iPhone__button--power" ></div>
                                        <div className="iPhone__frame">
                                            {/* <div className="iPhone__notch" ></div> */}
                                            <div className="iPhone__screen">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-7 p-5">
                                    <h2 className='pb-5'>Mobile</h2>
                                    <div className='row '>
                                        <div className='col-12 col-lg-6 mb-5 '>
                                            <div className='bg-black-opacity p-3'>
                                                <h3>PWA</h3>
                                                <p> Nous créons, déclinons votre site internet dans sa version mobile sous forme de Progressive Web App (<strong>PWA</strong> ) nommé également <strong>Application Web</strong>
                                                </p>
                                            </div>
                                        </div>
                                        <div className='col-12 col-lg-6 '>
                                            <div className='bg-black-opacity p-3'>
                                                <h3>SDK</h3>
                                                <p> Nous développons vos applications mobiles ANDROID et IOS (Iphone, Ipad ...)  grâce aux Software Development Kits (<strong>SDK</strong>) de type <strong>Flutter</strong></p>
                                            </div>
                                        </div>

                                    </div>

                                </div>



                            </div>
                        </nav>
                    </div>

                    {/*main page content end*/}
                </div>
            </div>
        </div>
    );
};

export default ExpertisesMobile;