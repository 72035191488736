import React from 'react';
import ExpertisesDesign from './ExpertisesDesign';
import ExpertisesDeveloppement from './ExpertisesDeveloppement';
import ExpertisesMobile from './ExpertisesMobile';
// import Linkedin from './Linkedin';

const Navbar = () => {
    return (
        <div>

            {/*Navigation*/}
            <nav className="navbar navbar-top-default navbar-expand-lg navbar-simple nav-box-round">
                <div className="container">
                    <a href="#accueil" className="logo scroll">
                        {/*Logo Default*/}
                        <h2>myDev</h2>
                    </a>
                    {/*Nav Links*/}
                    <div className="collapse navbar-collapse">
                        {/* <ul className="navbar-nav m-auto">

                            <li className="nav-item expertises">
                                <a title="Expertises" className="nav-link scroll" href="#expertises">Expertises</a>
                            </li>
                            <li className="nav-item demos">
                                <a title="Démos" className="nav-link scroll" href="#demos">Démos</a>
                            </li>
                            <li className="nav-item nos-outils">
                                <a title="Nos-outils" className="nav-link scroll" href="#nos-outils">Nos outils</a>
                            </li>
                            <li className="nav-item equipe">
                                <a title="L'équipe" className="nav-link scroll" href="#equipe">L'équipe</a>
                            </li>
                            <li className="nav-item contact">
                                <a title="Contact" className="nav-link scroll" href="#contact">Contact</a>
                            </li>
                            <li className="nav-item ou-sommes-nous">
                                <a title="ou-sommes-nous" className="nav-link scroll" href="#ou-sommes-nous"><span className='d-none'>ou-sommes-nous</span><i className="las la-map-marker-alt" ></i></a>
                            </li>
                        </ul> */}
                        <div className="navbar-nav sc m-auto">
                            <a title="Expertises" className="nav-link scroll expertises" href="#expertises">Expertises<span ></span></a>
                            <a title="Prestations" className="nav-link scroll demos" href="#demos">Prestations<span ></span></a>
                            <a title="Nos-outils" className="nav-link scroll nos-outils" href="#nos-outils">Nos outils<span ></span></a>
                            <a title="L'équipe" className="nav-link scroll equipe" href="#qui-sommes-nous">Qui-sommes-nous<span ></span></a>
                            <a title="Contact" className="nav-link scroll contact" href="#contact">Contact<span ></span></a>
                            <a title="Ou-sommes-nous" className="nav-link scroll ou-sommes-nous" href="#ou-sommes-nous"><span className='d-none'>ou-sommes-nous</span><i className="las la-map-marker-alt" ></i><span></span></a>
                        </div>
                    </div>
                    {/* <span className="linkedin" id='linkedin' ><i className="lab la-linkedin"></i></span> */}
                    <span className="d-inline-block sidemenu_btn d-lg-none" id="sidemenu_toggle">
                        <span></span>
                        <span ></span>
                        <span ></span>
                    </span>

                </div>
            </nav>
            {/*Side Nav*/}
            <div className="side-menu hidden">
                <div className="inner-wrapper">
                    <span className="btn-close" id="btn_sideNavClose"></span>
                    <nav className="side-nav w-100">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <a title="Accueil" className="nav-link scroll" href="#accueil"><span className='d-none'>accueil</span><i className="las la-home"></i></a>
                            </li>
                            <li className="nav-item">
                                <a title="Expertises" className="nav-link scroll" href="#expertises">Expertises</a>
                            </li>
                            <li className="nav-item">
                                <a title="Prestations" className="nav-link scroll" href="#demos">Prestations</a>
                            </li>
                            <li className="nav-item">
                                <a title="Nos-outils" className="nav-link scroll" href="#nos-outils">Nos outils</a>
                            </li>
                            <li className="nav-item">
                                <a title="L'équipe" className="nav-link scroll" href="#qui-sommes-nous">Qui-sommes-nous</a>
                            </li>
                            <li className="nav-item">
                                <a title="Contact" className="nav-link scroll" href="#contact">Contact</a>
                            </li>
                            <li className="nav-item">
                                <a title="ou-sommes-nous" className="nav-link scroll" href="#ou-sommes-nous"><span className='d-none'>ou-sommes-nous</span><i className="las la-map-marker-alt" ></i></a>
                            </li>
                        </ul>
                    </nav>
                    <div className="side-footer text-white-custom w-100">
                        <p className="text-white-custom">© 2021 myDev. Made With 💚</p>
                    </div>
                </div>
            </div>
            {/* End side menu */}
            <ExpertisesDeveloppement />
            <ExpertisesDesign />
            <ExpertisesMobile />
            {/* <Linkedin /> */}
        </div>

    );
};

export default Navbar;