import React from 'react';
import Particles from "react-tsparticles";
//import { loadSeaAnemonePreset } from "tsparticles-preset-sea-anemone";

const ParticulesDeveloppement = () => {

    const particlesInit = (main) => {
        //loadSeaAnemonePreset(main);

        // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
    };

    const particlesLoaded = () => {
    };

    const options = {
      fpsLimit: 60,
      fullScreen: {
        enable: false
      },
      background: {
        color: "#f5f5dc"
      },
      interactivity: {
        detect_on: "canvas",
        events: {
          onClick: { enable: true, mode: "push" },
          onHover: {
            enable: true,
            mode: "repulse",
            parallax: { enable: false, force: 60, smooth: 10 }
          },
          resize: true
        },
        modes: {
          bubble: { distance: 400, duration: 2, opacity: 0.8, size: 40, speed: 3 },
          grab: { distance: 400, links: { opacity: 1 } },
          push: { quantity: 4 },
          remove: { quantity: 2 },
          repulse: { distance: 200, duration: 0.4 }
        }
      },
      particles: {
        color: { value: "random" },
        links: {
          color: "random",
          distance: 150,
          enable: false,
          opacity: 0.4,
          width: 1
        },
        move: {
          attract: { enable: false, rotateX: 600, rotateY: 1200 },
          bounce: false,
          direction: "none",
          enable: true,
          out_mode: "out",
          random: false,
          speed: 3,
          straight: false
        },
        rotate: {
          animation: {
            enable: true,
            speed: 10,
            sync: false
          }
        },
        number: { density: { enable: true, area: 800 }, value: 100 },
        opacity: {
          animation: { enable: true, minimumValue: 0.5, speed: 1, sync: false },
          random: false,
          value: 1
        },
        shape: {
          options: {
            character: {
              fill: false,
              font: "Verdana",
              style: "",
              value: "*",
              weight: "400"
            },
            char: {
              fill: false,
              font: "Verdana",
              style: "",
              value: "*",
              weight: "400"
            },
            polygon: { sides: 5 },
            star: { sides: 5 },
            image: [
              {
                src:
                  "assets/img/logos/apache.svg",
                width: 32,
                height: 32
              },
              {
                src:
                "assets/img/logos/bootstrap.svg",
                width: 32,
                height: 32
              },
              {
                src:
                "assets/img/logos/css3.svg",
                width: 32,
                height: 32
              },
              {
                src:
                "assets/img/logos/debian.svg",
                width: 32,
                height: 32
              },
              {
                src:
                "assets/img/logos/git.svg",
                width: 32,
                height: 32
              },
              {
                src:
                "assets/img/logos/github.svg",
                width: 32,
                height: 32
              },
              {
                src:
                "assets/img/logos/linux.svg",
                width: 32,
                height: 32
              },
              {
                src:
                "assets/img/logos/apple.svg",
                width: 32,
                height: 32
              },
              {
                src:
                "assets/img/logos/react.svg",
                width: 32,
                height: 32
              },
              {
                src:
                "assets/img/logos/html5.svg",
                width: 32,
                height: 32
              },
              {
                src:
                "assets/img/logos/symfony.svg",
                width: 32,
                height: 32
              }
            ],
          
          },
          type: "image"
        },
        size: {
          anim: { enable: true, minimumValue: 8, speed: 20, sync: false },
          random: { minimumValue: 8, enable: true },
          value: 32
        }
      },
      detectRetina: true
      };

    return (
        <Particles options={options} 
        id="particuleDeveloppement"
        init={particlesInit}
        loaded={particlesLoaded} />
        );
};

export default ParticulesDeveloppement;