import React from 'react';

const Expertises = () => {
    return (
        <section className="single-items center-block parallax services-area" id="expertises" style={{ background: 'url(assets/img/slide-2.webp)', width: '100vw', height: '100vh' }}>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-6" ></div>
                    <div className="col-12 col-lg-6 center-col">
                        <div className="row no-gutters align-items-center">
                            <div className="col-12 col-md-6 d-inline-block justify-content-center align-items-center" >
                                <div className="feature-item text-center text-md-center cta-developpement" id="developpement">
                                    <i className="las la-code f-icon cta-developpement" ></i>
                                    <h2 className="title cta-developpement"><strong><span className='cta-developpement'>Développement</span></strong></h2>
                                    <p className="las la-arrow-right r-icon cta-developpement"> </p>
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="feature-item text-center text-md-center cta-design" id="design">
                                    <i className="las la-palette f-icon cta-design" ></i>
                                    <h2 className="title cta-design"><strong><span className='cta-design'>Design UI/UX</span></strong></h2>
                                    <p className="las la-arrow-right r-icon cta-design"> </p>
                                </div>
                                <div className="feature-item text-center text-md-center cta-mobile" id="mobile">
                                    <i className="las la-mobile f-icon cta-mobile" ></i>
                                    <h2 className="title cta-mobile"><strong><span className='cta-mobile'>Mobile</span></strong></h2>
                                    <p className="las la-arrow-right r-icon cta-mobile"> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
};

export default Expertises;