import React from 'react';
import Loader from './components/Loader';
import Header from './components/Header';
import Accueil from './components/Accueil';
import Expertises from './components/Expertises';
import Demos from './components/Demos';
import Equipe from './components/Equipe';
import Contact from './components/Contact';
// import NotFound from './components/NotFound';
import OuSommesNous from './components/OuSommesNous';
import MapGoogle from './components/MapGoogle';
import Footer from './components/Footer';
import ScrollTopArrow from './components/ScrollTopArrow';
import NosOutils from './components/NosOutils';
import BoutonRetour from './components/BoutonRetour';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";



const App = () => {
  let routes = <>
    <Loader />
    <Header />
    <Accueil />
    <Expertises />
    <Demos />
    <NosOutils />
    <Equipe />
    <Contact />
    <OuSommesNous />
    <MapGoogle />
    <Footer />
    <ScrollTopArrow />
    <BoutonRetour />
  </>

  let routesMobile = <>
    <Loader />
    <Header />
    <Accueil />
    <Expertises />
    <Demos />
    <NosOutils />
    <Equipe />
    <Contact />
    <OuSommesNous />
    <MapGoogle />
    <Footer />
    <ScrollTopArrow />
    <BoutonRetour />
  </>
  return (

    <>
      <Router>
        <Switch>
          <Route exact path="/">
            {routes}
          </Route>
          <Route exact path="/mobile">
            {routesMobile}
          </Route>
          <Route path="*">
            {routes}
          </Route>
        </Switch>
      </Router >

    </>
  );
};

export default App;




