import React from 'react';
import Particles from "react-tsparticles";
import { loadLinksPreset } from "tsparticles-preset-links";

const Particules = () => {

  const particlesInit = (main) => {
    loadLinksPreset(main);

    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
  };

  const particlesLoaded = () => {
  };

  const options = {
    preset: "links",
    background: {
      color: {
        value: "transparent",
      },
    },
    fpsLimit: 60,
    interactivity: {
      events: {
        onClick: {
          enable: true,
          mode: "push",
        },
        onHover: {
          enable: true,
          mode: "repulse",
        },
        resize: true,
      },
      modes: {
        bubble: {
          distance: 200,
          duration: 100,
          opacity: 1,
          size: 1,
        },
        push: {
          quantity: 1,
        },
        repulse: {
          distance: 100,
          duration: 0.4,
        },
      },
    },
    particles: {
      color: {
        value: "#ffffff",
      },
      links: {
        color: "#ffffff",
        distance: 120,
        enable: true,
        opacity: 0.7,
        width: 1,
      },
      collisions: {
        enable: true,
      },
      move: {
        direction: "none",
        enable: true,
        outMode: "bounce",
        random: false,
        speed: 1,
        straight: false,
      },
      number: {
        density: {
          enable: true,
          area: 800,
        },
        value: 150,
      },
      opacity: {
        value: 0.5,
      },

    },
    detectRetina: true,
  };

  return (
    <Particles options={options}
      id="tsparticlesAccueil"
      init={particlesInit}
      loaded={particlesLoaded} />
  );
};

export default Particules;