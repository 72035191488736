import React from 'react';

const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

const yoann = <>

    <div className='col-12 col-lg-4 p-0 p-lg-5 mb-5'>
        <div className="reviews item">
            <div className="review-body">
                <p className="user-comment">Je suis passionné par la Blockchain et les Crypto-actifs depuis quelques années.<br />Mon expérience Web3 mise à la disposition de vos projets les plus ambitieux.</p>
            </div>
            <div className="row  justify-content-center user-img ">
                <div className='card col-12 yoann animated  p-0  text-center card-center'>
                </div>
            </div>
            <h3 className="user-name animated">Yoann</h3>
            <p className="user-designation">- CEO -<br />Développeur Full stack</p>
        </div>
    </div>
</>

const benjamin = <>
    <div className='col-12 col-lg-4 p-0 p-lg-5 mb-5'>
        <div className="reviews item">
            <div className="review-body">
                <p className="user-comment">
                    Je suis développeur Web depuis 2009.<br />J'optimise ma veille technologique afin de vous présenter le meilleur de mes compétences.(React, Node.js, PHP, Mysql, HTML, CSS, DevOps, Solidity, Web3)
                </p>
            </div>
            <div className="row  justify-content-center user-img ">
                <div className='card col-12 benjamin animated  p-0  text-center card-center'>
                </div>
            </div>
            <h3 className="user-name">Benjamin</h3>
            <p className="user-designation">- CEO -<br />Développeur Full stack</p>
        </div>
    </div>
</>

const raphael = <>
    <div className='col-12 col-lg-4 p-0 p-lg-5 mb-5'>
        <div className="reviews item">
            <div className="review-body">
                <p className="user-comment">Mon appétence pour le back end et la sécurité permet d'optimiser l'invisible.<br />
                    Partageons notre imagination dans l'accomplissement de nos projets.</p>
            </div>
            <div className="row  justify-content-center user-img ">
                <div className='card col-12 raphael   p-0  text-center card-center' onClick={() => openInNewTab('https://www.linkedin.com/in/raphael-braga-66bb42175/')}>
                    <i className=" lab la-linkedin"></i>
                </div>
            </div>
            <h3 className="user-name">Raphaël</h3>
            <p className="user-designation">- Développeur Full stack -</p>
        </div>
    </div>
</>

let devs = [yoann, benjamin];
var randomDev = devs.sort(() => Math.random() - 0.5)

const Equipe = () => {
    return (
        <section className="single-items center-block item-seven parallax testimonial-sec lazy" id="qui-sommes-nous" style={{ background: 'url(assets/img/avis.webp)' }}>
            <div className="container">

                <div className='row no-gutters cards cards-equipe align-items-center text-center d-none d-lg-flex'>
                    <div className='col-12 text-center p-5 text-white d-flex align-items-center justify-content-center jumbotron-mydev-2'>
                        Nous sommes une agence web experte en communication marketing, création de logos, design UI/UX, conception et optimisation de sites web, ainsi qu'en développement Web3 et blockchain. Quels que soient vos besoins dans le secteur numérique, nos équipes de professionnels vous accompagneront pour vous offrir des solutions sur mesure. Elles travailleront sans relâche pour répondre à vos attentes et atteindre vos objectifs. Nous vous proposons des solutions adaptées à tous vos projets, qu'il s'agisse de sites vitrines ou du développement d'applications d'envergure internationale (App/DAPP...). Chez myDev, nous nous occupons de tout.


                    </div>
                    {/* {randomDev[0]}
                    {randomDev[1]} */}
                    {/* {randomDev[2]} */}
                </div>
                <div className="row align-items-center d-block d-lg-none ">
                    <div className="col-12 col-lg-5 text-center review-box">
                        <div className="row no-gutters slick-test cards cards-equipe">
                            <div className='col-12 text-center p-5 text-white d-flex align-items-center justify-content-center jumbotron-mydev-2'>
                                Nous sommes une agence web spécialisée dans la communication marketing, création de logo, DESIGN UI/UX, la création et l’optimisation de site internet et également dans le développement web3 & blockchain .
                                Quel que soit votre besoin dans le domaine du numérique, Nos équipes d'experts vous accompagneront pour vous proposer une solution adaptée. Elles mettront tout en oeuvre dans le but de vous satisfaire et de réaliser vos objectifs.
                                Nous vous proposerons des solutions pour tout vos projets aussi bien pour des sites vitrine que pour le développement d’une application d’envergure internationale (App/DAPP ...)<br />
                                myDev s’occupe de tout
                            </div>
                            {/* {randomDev[0]}
                            {randomDev[1]} */}
                            {/* {randomDev[2]} */}
                        </div>
                        <span className="navigate-btn prev-review-btn" ><span className="fly-line"></span>Prev <i className="las la-arrow-right"></i> </span>
                        <span className="navigate-btn next-review-btn" ><span className="fly-line"></span>Next <i className="las la-arrow-right"></i> </span>
                    </div>
                    <div className='col-12 col-lg-7'>



                    </div>
                </div>
            </div>
            <style className="hover" dangerouslySetInnerHTML={{ __html: "" }} />

        </section>

    );
};

export default Equipe;