import React from 'react';

const Loader = () => {
    return (
        <div className="loader center-block">
            <div className="spinner">
                <div className="spinner-container container1">
                    <div className="circle1" />
                    <div className="circle2" />
                    <div className="circle3" />
                    <div className="circle4" />
                </div>
                <div className="spinner-container container2">
                    <div className="circle1" />
                    <div className="circle2" />
                    <div className="circle3" />
                    <div className="circle4" />
                </div>
            </div>
        </div>

    );
};

export default Loader;