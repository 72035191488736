import React from 'react'

// import FlappyBird from './FlappyBird';
// import { Button } from 'react-bootstrap';

const Demos = () => {

  // const setShow = useState(false);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);



  const demoApplication = <>
    <div className="owl-item " subcategory="0" id="application" data-hash="application" style={{ background: 'url(assets/img/slide-3-1.webp) center 0 / cover no-repeat fixed', width: '100vw', height: '100vh', backgroundAttachment: 'scroll' }}>
      <div className="item project-area" >
        <div className="project-img order-1">
        </div>
        <div className="container">
          <div className="project-detail text-center text-lg-left order-2">
            <div className="row no-gutters">
              <div className="col-12 col-lg-4 animate-fade p-5 p-lg-0">

                <div className='jumbotron-mydev'>
                  <h2 className="project-heading mb-1">Application</h2>
                  <div className='icons-demo mb-5'>
                    <i className="las la-mobile"></i>
                    <i className="lab la-android"></i>
                    <i className="lab la-apple"></i>
                    {/* <i className="las la-gamepad"></i> */}
                  </div>
                  <p className="project-text text-onblue-bg w-100 m-0">
                    Développement d'applications<br /> Web et Mobile.
                  </p>
                  <div className='bottom-demo text-center'>
                    <div>
                      {/* <a className="btn btn-transparent-white rounded-pill disabled" rel="noopener noreferrer" href="./">
                        DEMO À VENIR
                      </a> */}
                    </div>
                    <div className='text-muted mt-4'>
                      <small>React - Canvas - HTML5 - PWA - SDK</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>

  const demoSiteVitrine = <>


    <div className="owl-item " subcategory="1" id="creation-site-web" data-hash="creation-site-web" style={{ background: 'url(assets/img/slide-3-2.webp) center 0 / cover no-repeat fixed', width: '100vw', height: '100vh', backgroundAttachment: 'scroll' }}>
      <div className="item project-area" >
        <div className="project-img order-1">

        </div>
        <div className="container">
          <div className="project-detail text-center text-lg-left order-2">
            <div className="row no-gutters">
              <div className="col-12 col-lg-4 animate-fade p-5 p-lg-0">
                <div className='jumbotron-mydev'>

                  <h2 className="project-heading mb-1">Site vitrine</h2>
                  <div className='icons-demo mb-5'>
                    <i className="lab la-html5"></i>
                    <i className="lab la-react "></i>
                    <i className="lab la-css3-alt"></i>
                  </div>
                  <p className="project-text text-onblue-bg w-100 m-0">
                    En navigant sur myDev,<br />vous êtes au coeur<br /> de ce que nous vous proposons<br />quant à la création de sites vitrines.
                  </p>
                  <div className='bottom-demo text-center'>
                    <div>

                    </div>
                    <div className='text-muted mt-4'>
                      <small>React - HTML5 - CSS - JavaScript - API</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>

  const demoSiteEcommerce = <>
    <div className="owl-item " subcategory="2" id="site-ecommerce" data-hash="site-ecommerce" style={{ background: 'url(assets/img/slide-3-3.webp) center 0 / cover no-repeat fixed', width: '100vw', height: '100vh', backgroundAttachment: 'scroll' }}>
      <div className="item project-area">
        <div className="project-img order-1">
        </div>
        <div className="container">
          <div className="project-detail text-center text-lg-left order-2">
            <div className="row no-gutters">
              <div className="col-12 col-lg-4 animate-fade p-5 p-lg-0">
                <div className='jumbotron-mydev'>
                  <h2 className="project-heading mb-1">Site e-commerce</h2>
                  <div className='icons-demo mb-5'>
                    <i className="lab la-php"></i>
                    <i className="lab la-symfony"></i>
                    <i className="lab la-stripe"></i>
                  </div>
                  <p className="project-text text-onblue-bg w-100">
                    Création de sites e-commerce.<br />
                    Scalable à la demande,<br />selon vos besoins.<br />

                  </p>
                  <div className='bottom-demo text-center'>
                    <div>
                      {/* <a className="btn btn-transparent-white rounded-pill demo-blockchain" target="_blank" rel="noopener noreferrer" href="https://shop.mydev.fr/">
                        DEMO <i className="las la-shopping-cart"></i>
                      </a> */}
                    </div>
                    <div className='text-muted mt-4'>
                      <small>Symfony 5 - PHP - MariaDB - HTML5 - Stripe</small>
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>

  const demoBlockchain = <>
    <div className="owl-item " subcategory="3" id="blockchain" data-hash="blockchain" style={{ background: 'url(assets/img/slide-3-4.webp) center 0 / cover no-repeat fixed', width: '100vw', height: '100vh', backgroundAttachment: 'scroll' }}>


      <div className="item project-area" >
        <div className="project-img order-1">
        </div>
        <div className="container">
          <div className="project-detail text-center text-lg-left order-2">
            <div className="row no-gutters">
              <div className="col-12 col-lg-4 animate-fade p-5 p-lg-0">
                <div className='jumbotron-mydev'>
                  <h2 className="project-heading mb-1">Blockchain</h2>
                  <div className='icons-demo mb-5'>
                    <i className="lab la-react "></i>
                    <i className="lab la-node-js"></i>
                    <i className="las la-cubes"></i>
                    <i className="lab la-ethereum"></i>
                  </div>
                  <p className="project-text text-onblue-bg w-100 line-height-20">
                    Réalisation de solutions logistiques Blockchain adaptées à vos besoins.<br />
                    Création de DAPP, Smart contract vérifié. Création et SWAP de Tokens, Projets NFT...
                  </p>

                  <div className='bottom-demo text-center'>
                    <div>
                      <span>
                        <small>Wallet MetaMask ou XDEFI requis</small><br />
                      </span>
                      {/* <a className="btn btn-transparent-white rounded-pill demo-shop disabled" rel="noopener noreferrer" href="./">
                        DEMO À VENIR
                      </a> */}
                    </div>
                    <div className='text-muted mt-4'>
                      <small>React - Node.js® - Solidity - Remix ETH - Truffle - Smart Contract <i className="las la-check-circle text-success"></i></small>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </>


  let demos = [demoApplication, demoSiteVitrine, demoSiteEcommerce, demoBlockchain];
  //let randomDemo = demos.sort(() => Math.random() - 0.5)
  let randomDemo = demos
  // let btn_0_name = useRef(randomDemo[0].props.children.props.id)
  // let btn_1_name = randomDemo[1].props.children.props.id
  // let btn_2_name = randomDemo[2].props.children.props.id
  // let btn_3_name = randomDemo[3].props.children.props.id

  // setBtn0(btn_0_name)
  return (
    <section className="portfolio" id="demos">
      <div id="project-sec" className="project-sec">

        <div className="projects owl-carousel owl-theme no-gutters owl-loaded owl-drag">
          <div className="owl-stage-outer">
            <div className="owl-stage" style={{ transform: 'translate3d(-3984px, 0px, 0px)', transition: 'all 0s linear 0s', width: 5312 }}>

              {randomDemo[0]}
              {randomDemo[1]}
              {randomDemo[2]}
              {randomDemo[3]}
            </div>
          </div>
        </div>







        <span className="customPrevBtn"><i className="las la-angle-left"></i></span>
        <span className="customNextBtn"><i className="las la-angle-right"></i></span>




      </div>


    </section >
  );
};

export default Demos;

