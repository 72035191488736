import React from 'react';
import Particles from "react-tsparticles";
//import { loadSeaAnemonePreset } from "tsparticles-preset-sea-anemone";

const ParticulesErgonomie = () => {

  const particlesInit = (main) => {
    //loadSeaAnemonePreset(main);

    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
  };

  const particlesLoaded = () => {
  };

  const options = {
    background: {
      color: "#000000",
      image: "",
      position: "50% 50%",
      repeat: "no-repeat",
      size: "cover"
    },
    fpsLimit: 60,
    fullScreen: {
      enable: false
    },
    particles: {
      number: {
        value: 200,
        density: {
          enable: true,
          value_area: 631.3280775270874
        }
      },
      color: {
        value: "#fff"
      },
      shape: {
        type: "circle",
      
      },
      opacity: {
        value: 1,
        random: true,
        anim: {
          enable: false,
          speed: 2,
          opacity_min: 0.1,
          sync: false
        }
      },
      size: {
        value: 2,
        random: true,
        anim: {
          enable: false,
          speed: 40,
          size_min: 0.1,
          sync: true
        }
      },
      line_linked: {
        enable: false,
        distance: 500,
        color: "#ffffff",
        opacity: .0001,
        width: 2
      },
      move: {
        angle: {
          value: 10,
          offset: 0
        },
        enable: true,
        speed: 2,
        direction: "right",
        random: false,
        straight: false,
        out_mode: "out",
        bounce: false,
        attract: {
          enable: false,
          rotateX: 600,
          rotateY: 1200
        }
      }
    },
    interactivity: {
      events: {
        onClick: {
          enable: true,
          mode: "push",
        },
        onHover: {
          enable: true,
          mode: "repulse",
        },
        resize: true,
      },
      modes: {
        bubble: {
          distance: 200,
          duration: 100,
          opacity: 1,
          size: 1,
        },
        push: {
          quantity: 1,
        },
        repulse: {
          distance: 100,
          duration: 0.4,
        },
      },
    },
   
    retina_detect: true,
    emitters: {
      position: {
        y: 55,
        x: -30
      },
      rate: {
        delay: 2,
        quantity: 1
      },
      size: {
        width: 0,
        height: 0
      },
      particles: {
        shape: {
          type: "images",
          options: {
            images: [
              {
                src: "https://particles.js.org/images/amongus_blue.png",
                width: 205,
                height: 267
              },
              {
                src: "https://particles.js.org/images/amongus_cyan.png",
                width: 207,
                height: 265
              },
              {
                src: "https://particles.js.org/images/amongus_green.png",
                width: 204,
                height: 266
              },
              {
                src: "https://particles.js.org/images/amongus_lime.png",
                width: 206,
                height: 267
              },
              {
                src: "https://particles.js.org/images/amongus_orange.png",
                width: 205,
                height: 265
              },
              {
                src: "https://particles.js.org/images/amongus_pink.png",
                width: 205,
                height: 265
              },
              {
                src: "https://particles.js.org/images/amongus_red.png",
                width: 204,
                height: 267
              },
              {
                src: "https://particles.js.org/images/amongus_white.png",
                width: 205,
                height: 267
              }
            ]
          }
        },
        size: {
          value: 40
        },
        move: {
          speed: 5,
          outModes: {
            default: "destroy",
            left: "none"
          },
          straight: false
        },
        zIndex: {
          value: 0
        },
        rotate: {
          value: {
            min: 0,
            max: 360
          },
          animation: {
            enable: true,
            speed: 4,
            sync: true
          }
        }
      }
    }
  };

  return (
    <Particles options={options}
      id="particulesErgonomie"
      init={particlesInit}
      loaded={particlesLoaded} />
  );
};

export default ParticulesErgonomie;