import React from 'react';
import Particules from './Particules';
const Accueil = () => {
    return (
        <section className="single-items slider-items p-0" id="accueil">
            <div className="item slider-inner center-block parallax m-0" style={{ height: '100vh', width: '100vw', backgroundSize: 'cover', backgroundAttachment: 'scroll' }}>
                <div className="container">
                    <Particules />
                    <div className="row mt-5">
                        <div className="col-12 col-lg-9 center-col">
                            <div className="area-heading text-center text-lg-left wow fadeInUp mt-5">
                                <h1 className="area-title text-capitalize alt-font text-white-custom mb-2 font-weight-100">Création de sites web</h1>
                                <h2 className='color-white'>Expériences numériques sur mesure</h2>
                                <p className="text-white-custom pt-4">
                                    Avec plus de quinze ans d'expérience dans le domaine du développement web, notre équipe vous offre des solutions astucieuses et innovantes pour la stratégie de marque, l'identité visuelle, la conception web et la technologie blockchain.
                                </p>
                                <p className="text-white-custom pb-4"><strong>Agence numérique</strong> spécialisée en <strong>communication</strong>, création de <strong>Sites internet</strong> et <strong>Applications</strong> <strong>Web3</strong></p>
                                <a id="btnContact" className="btn btn-transparent-white btn-rounded btn-rounded btn-large mt-3 scroll" href="#contact">NOUS CONTACTER</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >

    );
};

export default Accueil;