import React from 'react';

const NosOutils = () => {
    return (
        <section className="single-items  center-block parallax counter-sec position-relative" id="nos-outils" style={{ background: 'url(assets/img/slide-4.webp)' }}>
            <div className="row no-gutters mb-5 text-center">
                <div className="col-12  animate-fade">
                    <p className="text-white-custom w-50  m-auto text-center">
                        <i className="las la-quote-left" ></i>
                        La confiance se gagne quand les promesses se tiennent...
                        <i className="las la-quote-right" ></i>
                    </p>
                </div>
            </div>
            <div className="container-fluid  bg-white-transparent bottom-section">
                <div className="row">
                    <div className="col-12">
                        <div className="sponser-tags owl-carousel owl-theme">
                            <div className="item">
                                <img src="assets/img/technologies/symfony-logo.png" loading="lazy" alt="symfony logo" width={100} height={100} />
                            </div>
                            <div className="item">
                                <img src="assets/img/technologies/html-js-css-logo.png" loading="lazy" alt="html js css logo" width={100} height={100} />
                            </div>
                            <div className="item">
                                <img src="assets/img/technologies/react-logo.png" loading="lazy" alt="react logo" width={100} height={100} />
                            </div>
                            <div className="item">
                                <img src="assets/img/technologies/node-logo.png" loading="lazy" alt="node logo" width={100} height={100} />
                            </div>
                            <div className="item">
                                <img src="assets/img/technologies/bootstrap-logo.png" loading="lazy" alt="bootstrap logo" width={100} height={100} />
                            </div>
                            <div className="item">
                                <img src="assets/img/technologies/jquery-logo.png" loading="lazy" alt="jquery logo" width={100} height={100} />
                            </div>
                            <div className="item">
                                <img src="assets/img/technologies/git-logo.png" loading="lazy" alt="git logo" width={100} height={100} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


    );
};

export default NosOutils;